<template>
 <MobileHeader
    :mobileHeaderTitle="'ANNOUNCEMENT'"
    :tabs="$route.name"
  ></MobileHeader>
    <div class="container has-space has-header">
        <h1 class="page-title announcement-title" v-if="!isMobile">{{ $t('ANNOUNCEMENT') }}</h1>
        <div class="announcement-container">
            <h2 class="announcement-content__title">{{ announcement.title }}</h2>
            <div class="announcement-content" v-html="announcement.content"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MobileHeader from "@/components/mobile/Header.vue";

export default {
    data() {
        return {
            routeId: '',
            announcement: {}
        }
    },
    components: {
        MobileHeader,
    },
    computed: {
        ...mapGetters([
            "isMobile",
            "isLogin",
            "currentPathName"
        ]),
    },
    mounted() {
         this.init();
    },
    
    watch: {
        $route: {
            immediate: true,
            handler(newVal,oldVal) {
                if (oldVal) {
                    if (newVal.name === oldVal.name) {
                        this.init()
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions([
            "getNotificationAnnouncement"
        ]),

        init() {
            this.routeId = this.$route.params.id;
            this.$nextTick(() => {
                this.getNotificationAnnouncementData();
            });
        },

        async getNotificationAnnouncementData() {
            let params = {
                id: this.routeId
            }

            try {
                const result = await this.getNotificationAnnouncement(params);
                if (result.result.businessCode !== 0) {
                    if (this.isLogin) {
                        this.$router.push({name: 'home'})
                    } else {
                        this.$router.push({name: 'signin', query: { callBackUrl: this.currentPathName } })
                    }
                } else {
                    this.announcement = result.result.data;
                }
            } catch (err) {
                if (this.isLogin) {
                    this.$router.push({name: 'home'})
                } else {
                    this.$router.push({name: 'signin', query: { callBackUrl: this.currentPathName } })
                }
    
            }
           
           
        }
    },
}
</script>

<style>
.announcement-container{
    width: 49.6875rem;
    margin: 0 auto;
}
.announcement-title{
    margin-bottom: 5.5rem;
}
.announcement-content__title{
    font-size: 2.125rem;
    margin-bottom: 1.5rem;
}
.announcement-content{
    margin-bottom: 6.625rem
}
.announcement-content img{
    max-width: 100% !important
}
.announcement-content video{
    max-width: 100% !important
}
.announcement-content iframe{
    max-width: 100% !important;
}
@media (max-width: 768px) {
  .announcement-container{
        width: 100%;
        margin: 1rem 0
  }  
  .announcement-content__title{
        font-size: 1.125rem;
        margin-bottom: .5rem;
  }
  .announcement-content{
        margin-bottom: 2.625rem
  }
  .announcement-content iframe{
        height: 57vw
    }
}

</style>